import React from "react";
import { Heading, Subheading } from "./heading";
import { Strong, Text, TextLink } from "./text";
import ReactMarkdown from "react-markdown";
import { Divider } from "./divider";
// https://github.com/remarkjs/remark-gfm?tab=readme-ov-file#what-is-this
import remarkGfm from "remark-gfm";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./table";

interface MarkdownProps {
  markdown: string | null | undefined;
}

const Markdown: React.FC<MarkdownProps> = ({ markdown }) => {
  if (markdown === null || markdown === undefined) {
    return null;
  }

  return (
    <>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          h1: ({ node, ...props }) => (
            <>
              <Heading {...props} className="mt-12 mb-0.5" />
              <Divider className="mb-4" />
            </>
          ),
          h2: ({ node, ...props }) => (
            <>
              <Subheading {...props} className="mt-8" />
              <Divider className="mb-2" />
            </>
          ),
          h3: ({ node, ...props }) => <Subheading level={2} {...props} />,
          h4: ({ node, ...props }) => <Subheading level={2} {...props} />,
          h5: ({ node, ...props }) => <Subheading level={2} {...props} />,
          h6: ({ node, ...props }) => <Subheading level={2} {...props} />,
          strong: ({ node, ...props }) => <Strong {...props} />,

          // Unordered list
          ul: ({ node, ...props }) => (
            <ul
              style={{ paddingLeft: "20px", listStyleType: "disc" }}
              className="text-base/6 text-zinc-500 sm:text-lg/6 dark:text-zinc-400"
              {...props}
            />
          ),

          // Ordered list
          ol: ({ node, ...props }) => (
            <ol
              style={{ paddingLeft: "20px", listStyleType: "decimal" }}
              className="text-base/6 text-zinc-500 sm:text-lg/6 dark:text-zinc-400"
              {...props}
            />
          ),

          // List item
          li: ({ ...props }) => (
            <li style={{ marginBottom: "8px" }} {...props} />
          ),

          // Link handling with custom TextLink component
          a: ({ href, children, ...props }) => (
            <TextLink href={href ?? ""} isVioletParent={true} {...props}>
              {children}
            </TextLink>
          ),

          // Horizontal rule support using Divider component
          hr: () => <Divider />,

          p: ({ children }) => <Text>{children}</Text>,

          // Table support
          table: ({ node, ...props }) => <Table className="my-8" {...props} />,

          thead: ({ node, ...props }) => <TableHead {...props} />,

          tbody: ({ node, ...props }) => <TableBody {...props} />,

          tr: ({ node, ...props }) => <TableRow {...props} />,

          th: ({ node, ...props }) => <TableHeader className="" {...props} />,

          td: ({ node, ...props }) => <TableCell className="" {...props} />,
        }}
      >
        {markdown}
      </ReactMarkdown>
    </>
  );
};

export default Markdown;
