import { useMemo } from "react";

interface TimestampProps {
  timestamp: Date | null | undefined;
  display?:
    | "seconds" // display seconds also
    | "minutes"; // do not display seconds
}

const Timestamp: React.FC<TimestampProps> = ({
  timestamp = null,
  display = "minutes",
}) => {
  const formattedDate = useMemo(() => {
    if (timestamp === null) {
      return "--.--.----, --:--";
    }

    switch (display) {
      case "minutes": {
        return new Date(timestamp).toLocaleString("de-AT", {
          hour: "2-digit",
          minute: "2-digit",
          year: "numeric",
          month: "numeric",
          day: "numeric",
          timeZone: "Europe/Vienna",
        });
      }
      case "seconds": {
        return new Date(timestamp).toLocaleString("de-AT", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          year: "numeric",
          month: "numeric",
          day: "numeric",
          timeZone: "Europe/Vienna",
        });
      }
    }
  }, [timestamp, display]);

  return <>{formattedDate}</>;
};

export default Timestamp;
