import clsx from "clsx";
import { Link } from "./link";
import React, { useCallback, useEffect, useState } from "react";
import { juhuu } from "../juhuuClass";
import { JUHUU } from "@juhuu/sdk-ts";
import Spinner from "./spinner";
import { useLanguage } from "../context/LanguageContext";

export function Text({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"p">) {
  return (
    <p
      data-slot="text"
      {...props}
      className={clsx(
        className,
        "text-lg/6 text-zinc-500 sm:text-lg/6 dark:text-zinc-400"
      )}
    />
  );
}

export function TextLink({
  className,
  isVioletParent = false,
  children,
  href,
  ...props
}: React.ComponentPropsWithoutRef<typeof Link> & { isVioletParent?: boolean }) {
  const [isHovered, setIsHovered] = useState(false);
  const [openCard, setOpenCard] = useState(false);
  const [article, setArticle] = useState<JUHUU.Article.Object | null>(null);
  const [articleError, setArticleError] = useState<boolean>(false);
  const { currentLanguageCode } = useLanguage();

  const handleOnHover = useCallback(async () => {
    if (isHovered === false) {
      return;
    }

    if (href?.startsWith("https://docs.juhuu.app/articles/") === false) {
      return;
    }

    setOpenCard(true);

    if (articleError === true) {
      return;
    }

    // check if article is already fetched
    if (article !== null) {
      return;
    }

    const articleId = href.split("/").pop(); // Get the article ID from the URL
    console.log("articleId", articleId);

    if (articleId === undefined) {
      setArticleError(true);
      return;
    }

    const query = await juhuu.articles.retrieve({
      articleId: articleId,
    });

    if (query.ok === false) {
      setArticleError(true);
      return;
    }

    if (query.data.article.title[currentLanguageCode] === undefined) {
      setArticleError(true);
      return;
    }

    setArticle(query.data.article);
  }, [isHovered, href, article, articleError, currentLanguageCode]);

  useEffect(() => {
    handleOnHover();
  }, [isHovered, handleOnHover]);

  useEffect(() => {
    if (isHovered === false) {
      setOpenCard(false);
    }
  }, [isHovered]);

  return (
    <div className="relative inline-block">
      <Link
        {...props}
        href={href}
        className={clsx(
          className,
          "text-violet-500 hover:text-violet-700 font-bold decoration-zinc-950/50 data-[hover]:decoration-zinc-950 dark:text-white dark:decoration-white/50 dark:data-[hover]:decoration-white"
        )}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {React.Children.map(children, (child) =>
          React.isValidElement(child)
            ? React.cloneElement(
                child as React.ReactElement<{ isVioletParent?: boolean }>,
                { isVioletParent }
              )
            : child
        )}
      </Link>

      {/* Conditionally render the preview div */}
      {openCard && (
        <div className="absolute z-10 p-4 bg-white border border-gray-300 shadow-lg rounded-md dark:bg-zinc-800 dark:border-zinc-600 w-64 min-h-8">
          {article === null ? (
            <div className="flex justify-center items-center w-full">
              {articleError ? <Text>No preview available</Text> : <Spinner />}
            </div>
          ) : (
            <>
              <h3 className="font-bold text-lg text-zinc-900 dark:text-white">
                {article?.title?.[currentLanguageCode]}
              </h3>
              <p className="text-sm text-zinc-600 dark:text-zinc-400">
                {article?.subtitle?.[currentLanguageCode]}
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export function Strong({
  className,
  isVioletParent = false,
  ...props
}: React.ComponentPropsWithoutRef<"strong"> & { isVioletParent?: boolean }) {
  // console.log("isVioletParent Strong", isVioletParent);
  return (
    <strong
      {...props}
      className={clsx(
        className,
        "font-medium",
        // Conditionally apply color based on the parent's text color
        isVioletParent ? "" : "font-medium text-zinc-950 dark:text-white"
      )}
    />
  );
}

export function Code({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"code">) {
  return (
    <code
      {...props}
      className={clsx(
        className,
        "rounded border border-zinc-950/10 bg-zinc-950/[2.5%] px-0.5 text-sm font-medium text-zinc-950 sm:text-[0.8125rem] dark:border-white/20 dark:bg-white/5 dark:text-white"
      )}
    />
  );
}
