import React from "react";
import clsx from "clsx";

interface AlertBoxProps {
  children?: React.ReactNode;
  color?: "amber" | "lime" | "zinc";
}

const AlertBox: React.FC<AlertBoxProps> = ({ children, color = "amber" }) => {
  const colorClasses = {
    amber:
      "border-amber-500 bg-amber-200 text-amber-700 dark:bg-amber-500 dark:text-black dark:border-amber-700",
    lime: "border-lime-500 bg-lime-200 text-lime-700 dark:bg-lime-500 dark:text-black dark:border-lime-700",
    zinc: "border-zinc-500 bg-zinc-200 text-zinc-700 dark:bg-zinc-500 dark:text-black dark:border-zinc-700",
  };

  return (
    <div className={clsx("p-4 rounded-xl my-5", colorClasses[color])}>
      {children}
    </div>
  );
};

export default AlertBox;
