import React, { useMemo } from "react";
import { LanguageCode } from "@juhuu/sdk-ts";

interface LanguageCodeeeProps {
  languageCode: LanguageCode;
}

/*
export interface LocaleString {
  en?: string; // english is mandatory!
  de?: string;
  fr?: string;
  it?: string;
  nl?: string;
  es?: string;
  da?: string;
  hr?: string;
  hu?: string;
  no?: string;
  pl?: string;
  sv?: string;
  cs?: string;
  et?: string;
}*/

const LanguageCodeee: React.FC<LanguageCodeeeProps> = ({ languageCode }) => {
  const text: string | null = useMemo(() => {
    switch (languageCode) {
      case "en": {
        return "English";
      }

      case "de": {
        return "Deutsch";
      }

      case "fr": {
        return "Français";
      }

      case "it": {
        return "Italiano";
      }

      case "nl": {
        return "Nederlands";
      }

      case "es": {
        return "Español";
      }

      case "da": {
        return "Dansk";
      }

      case "hr": {
        return "Hrvatski";
      }

      case "hu": {
        return "Magyar";
      }

      case "no": {
        return "Norsk";
      }

      case "pl": {
        return "Polski";
      }

      case "sv": {
        return "Svenska";
      }

      case "cs": {
        return "Čeština";
      }

      case "et": {
        return "Eesti";
      }

      default: {
        return "-";
      }
    }
  }, [languageCode]);

  return <>{text}</>;
};

export default LanguageCodeee;
