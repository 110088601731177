import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarHeading,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
} from "./components/sidebar";
import { useEffect, useState } from "react";
import { Navbar, NavbarItem, NavbarSection } from "./components/navbar";
import {
  LanguageIcon,
  LifebuoyIcon,
  MapPinIcon,
  MoonIcon,
  QrCodeIcon,
  QuestionMarkCircleIcon,
  RectangleStackIcon,
  ShieldCheckIcon,
  ShoppingBagIcon,
  SunIcon,
  TagIcon,
  UsersIcon,
} from "@heroicons/react/16/solid";
import { Switch, SwitchField } from "./components/switch";
import { Label } from "./components/fieldset";
import { useLanguage } from "./context/LanguageContext";
import LanguageCodeee from "./context/LanguageCodeee";
import { SidebarLayout } from "./components/sidebar-layout";
import { Heading } from "./components/heading";
import { Avatar } from "./components/avatar";
import logo from "./logo.png";
import { useLocation } from "react-router-dom";
interface PageProps {
  children: React.ReactNode;
}

const Page: React.FC<PageProps> = ({ children }) => {
  // const location = useLocation();
  const { openLanguageSelector, currentLanguageCode } = useLanguage();
  const [colorScheme, setColorScheme] = useState<"dark" | "light">("light");
  const location = useLocation();

  const handleToggleColorScheme = () => {
    console.log("toggle theme");
    if (colorScheme === "dark") {
      localStorage.setItem("theme", "light");
      setColorScheme("light");
    } else {
      localStorage.setItem("theme", "dark");
      setColorScheme("dark");
    }
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "dark") {
      setColorScheme("dark");
    } else {
      setColorScheme("light");
    }
  }, []);

  return (
    <div
      className={colorScheme}
      style={{
        overflowX: "hidden",
        minHeight: "100vh", // Ensure full-page height
        position: "relative",
      }}
    >
      <SidebarLayout
        navbar={<Heading>JUHUU Docs</Heading>}
        sidebar={
          <Sidebar>
            <SidebarHeader>
              <div className="flex gap-4 justify-start flex-row items-center">
                <img src={logo} className="h-10 w-10" alt="JUHUU Logo" />
                <Heading>JUHUU Docs</Heading>
              </div>
              <SidebarSection></SidebarSection>
            </SidebarHeader>
            <SidebarBody>
              <SidebarSection>
                <SidebarHeading className="mt-8">Customer Needs</SidebarHeading>
                <SidebarItem
                  href={"/articles/67039cd235ed1ec9444a70f5"}
                  current={
                    location.pathname.split("/")[2] ===
                    "67039cd235ed1ec9444a70f5"
                  }
                >
                  <SidebarLabel>Municipality or Company</SidebarLabel>
                </SidebarItem>
                <SidebarItem
                  href={"/articles/67039d9e35ed1ec9444a7136"}
                  current={
                    location.pathname.split("/")[2] ===
                    "67039d9e35ed1ec9444a7136"
                  }
                >
                  <SidebarLabel>Moblity Service Provider</SidebarLabel>
                </SidebarItem>
                <SidebarItem
                  href={"/articles/67039d7235ed1ec9444a7113"}
                  current={
                    location.pathname.split("/")[2] ===
                    "67039d7235ed1ec9444a7113"
                  }
                >
                  <SidebarLabel>Manufacturer</SidebarLabel>
                </SidebarItem>
                <SidebarItem
                  href={"/articles/67039dcc35ed1ec9444a7159"}
                  current={
                    location.pathname.split("/")[2] ===
                    "67039dcc35ed1ec9444a7159"
                  }
                >
                  <SidebarLabel>Retailer</SidebarLabel>
                </SidebarItem>
                <SidebarItem
                  href={"/articles/67039df735ed1ec9444a717c"}
                  current={
                    location.pathname.split("/")[2] ===
                    "67039df735ed1ec9444a717c"
                  }
                >
                  <SidebarLabel>Consumer Platform</SidebarLabel>
                </SidebarItem>
                <SidebarItem
                  href={"/articles/67039e3735ed1ec9444a719f"}
                  current={
                    location.pathname.split("/")[2] ===
                    "67039e3735ed1ec9444a719f"
                  }
                >
                  <SidebarLabel>Users of the JUHUU App</SidebarLabel>
                </SidebarItem>
                <SidebarHeading className="mt-8">
                  Overview articles
                </SidebarHeading>

                <SidebarItem
                  href={"/articles/671fcc54c8630c4cff33de82"}
                  current={
                    location.pathname.split("/")[2] ===
                    "671fcc54c8630c4cff33de82"
                  }
                >
                  <SidebarLabel>General</SidebarLabel>
                </SidebarItem>
                <SidebarItem
                  href={"/articles/671fd26cc8630c4cff33df84"}
                  current={
                    location.pathname.split("/")[2] ===
                    "671fd26cc8630c4cff33df84"
                  }
                >
                  <SidebarLabel>Dashboard</SidebarLabel>
                </SidebarItem>
                <SidebarItem
                  href={"/articles/671fd2a2c8630c4cff33e056"}
                  current={
                    location.pathname.split("/")[2] ===
                    "671fd2a2c8630c4cff33e056"
                  }
                >
                  <SidebarLabel>Marketplace</SidebarLabel>
                </SidebarItem>
                <SidebarItem
                  href={"/articles/671fd2e1c8630c4cff33e129"}
                  current={
                    location.pathname.split("/")[2] ===
                    "671fd2e1c8630c4cff33e129"
                  }
                >
                  <SidebarLabel>Digitalize Kit</SidebarLabel>
                </SidebarItem>
              </SidebarSection>
            </SidebarBody>

            <SidebarFooter>
              <SidebarItem>
                {colorScheme === "dark" ? <MoonIcon /> : <SunIcon />}
                <SwitchField>
                  <Label>Darkmode</Label>
                  <Switch
                    onChange={handleToggleColorScheme}
                    checked={colorScheme === "dark"}
                  />
                </SwitchField>
              </SidebarItem>
              <SidebarItem onClick={() => openLanguageSelector()}>
                <LanguageIcon />
                <SidebarLabel>
                  <LanguageCodeee languageCode={currentLanguageCode} />
                </SidebarLabel>
              </SidebarItem>
              <SidebarItem
                href="https://juhuu.app/contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LifebuoyIcon />
                <SidebarLabel>Contact us</SidebarLabel>
              </SidebarItem>
            </SidebarFooter>
          </Sidebar>
        }
      >
        <div className="mx-auto max-w-3xl">{children}</div>
      </SidebarLayout>
    </div>
  );
};

export default Page;
