import React from "react";
import { Route, Routes } from "react-router-dom";
import Page from "./Page";
import ScrollToTop from "./routes/ScrollToTop";
import ArticleRoute from "./routes/articles/ArticleRoute";

function App() {
  // function onClose() {
  //   console.log("close");
  // }

  // const { accountSetupCompleted } = useUser();

  return (
    <>
      {/* <Dialog open={accountSetupCompleted === false} onClose={onClose}>
        <DialogTitle>Login or signup</DialogTitle>
        <DialogDescription>
          Before continuing to the dashboard, please login or signup with your
          JUHUU-Account.
        </DialogDescription>
        <DialogActions>
          <Button
            plain
            onClick={() => window.open("https://identity.juhuu.app/support")}
          >
            Help & Support
          </Button>
          <Button
            onClick={() =>
              (window.location.href =
                "https://identity.juhuu.app/auth?refURL=https://dashboard.juhuu.app")
            }
          >
            Login or signup
          </Button>
        </DialogActions>
      </Dialog> */}
      <Page>
        <ScrollToTop />
        <Routes>
          <Route path="*" element={<ArticleRoute />} />
          <Route path="/articles/:articleId" element={<ArticleRoute />} />
        </Routes>
      </Page>
    </>
  );
}

export default App;
