import React, { useCallback, useEffect, useMemo, useState } from "react";
import { juhuu } from "../../juhuuClass";
import { JUHUU, LanguageCode } from "@juhuu/sdk-ts";
import { useLocation, useParams } from "react-router-dom";
import { Strong, Text, TextLink } from "../../components/text";
import Markdown from "../../components/markdown";
import Spinner from "../../components/spinner";
import { Heading } from "../../components/heading";
import { useLanguage } from "../../context/LanguageContext";
import LanguageCodeee from "../../context/LanguageCodeee";
import AlertBox from "../../components/alert-box";
import { Button } from "../../components/button";
import { Li, Ul } from "../../components/list";
import Timestamp from "../../components/Timestamp";
import { Badge } from "../../components/badge";

interface ArticleRouteProps {}

const ArticleRoute: React.FC<ArticleRouteProps> = () => {
  const [article, setArticle] = useState<JUHUU.Article.Object | null>(null);
  const { articleId } = useParams<{ articleId: string }>();
  const location = useLocation();
  const [notFoundError, setNotFoundError] = useState(false);
  const [aiTranslationLoading, setAiTranslationLoading] = useState(false);
  const [anotherError, setAnotherError] = useState(false);
  const [property, setProperty] = useState<JUHUU.Property.Object | null>(null);
  const { currentLanguageCode, setLanguage } = useLanguage();

  const articleExistsInCurrentLanguage = useMemo(() => {
    if (article === null) {
      return false;
    }

    return article.title?.[currentLanguageCode] !== undefined;
  }, [article, currentLanguageCode]);

  const availableLanguageCodeArray: LanguageCode[] =
    useMemo((): LanguageCode[] => {
      if (article === null) {
        return [];
      }

      return Object.keys(article.title ?? {}) as LanguageCode[];
    }, [article]);

  useEffect(() => {
    if (article?.propertyId === undefined) {
      return;
    }

    juhuu.properties
      .retrieve({
        propertyId: article.propertyId,
      })
      .then((query) => {
        if (query.ok === false) {
          return;
        }

        setProperty(query.data.property);
      });
  }, [article?.propertyId]);

  const handleRefresh = useCallback(async () => {
    setNotFoundError(false);
    setAnotherError(false);
    if (articleId === undefined) {
      const query = await juhuu.articles.search({
        slug: location.pathname,
      });

      if (query.ok === false) {
        setAnotherError(true);
        return;
      }

      if (query.data.articleArray.length === 0) {
        setNotFoundError(true);
        return;
      } else if (query.data.articleArray.length > 1) {
        setNotFoundError(true);
        return;
      }

      setArticle(query.data.articleArray[0]);
    } else {
      const query = await juhuu.articles.retrieve({
        articleId: articleId,
      });

      if (query.ok === false) {
        setAnotherError(true);
        return;
      }

      setArticle(query.data.article);
    }
  }, [articleId, location]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  useEffect(() => {
    if (article === null) {
      return;
    }
  }, [article]);

  const handleTranslate = useCallback(async () => {
    if (article === null) {
      return;
    }
    setAiTranslationLoading(true);
    const query = await juhuu.articles.translate({
      articleId: article.id,
      languageCode: currentLanguageCode,
    });
    setAiTranslationLoading(false);

    if (query.ok === false) {
      return;
    }

    setArticle(query.data.article);
  }, [article, currentLanguageCode]);

  return (
    <>
      {article !== null &&
        article.languageCodeArray.includes(currentLanguageCode) === false &&
        availableLanguageCodeArray.length > 0 &&
        articleExistsInCurrentLanguage === true && (
          <>
            <AlertBox color="amber">
              The article you are reading was not originally published in{" "}
              <LanguageCodeee languageCode={currentLanguageCode} />. It was
              translated by AI and might contain errors in the translation. We
              do not take any responsibility for the correctness of the
              translation.
            </AlertBox>
          </>
        )}
      {article === null ? (
        <div className="flex flex-col justify-start items-center w-full">
          {notFoundError === true ? (
            <>
              <Text>The article could not be found.</Text>
              <Text>
                This happened, because its url changed, it was deleted, or it
                never existed in the first place.
              </Text>
            </>
          ) : (
            <>
              {anotherError === true ? (
                <Text>
                  The article could not be retrieve, please try to reload the
                  page.
                </Text>
              ) : (
                <Spinner />
              )}
            </>
          )}
        </div>
      ) : (
        <>
          {articleExistsInCurrentLanguage === false ? (
            <div className="flex flex-col justify-start items-center w-full">
              <Text>
                This article is not yet available in{" "}
                <LanguageCodeee languageCode={currentLanguageCode} />.
              </Text>
              <Text className="mb-4">
                Please select one of the following languages to read the
                article:
              </Text>
              {availableLanguageCodeArray.map((languageCode) => {
                return (
                  <Text
                    key={languageCode}
                    className="text-lg rounded-full cursor-pointer py-1"
                    onClick={() => {
                      setLanguage(languageCode);
                    }}
                  >
                    <Strong>
                      <LanguageCodeee languageCode={languageCode} />
                    </Strong>
                  </Text>
                );
              })}
              {article.autoTranslateEnabled === true && (
                <div className="mt-4 flex gap-4 flex-row justify-center items-center">
                  {aiTranslationLoading === true ? (
                    <>
                      <Spinner />
                      <Text>This process might take 30 seconds.</Text>
                    </>
                  ) : (
                    <Button onClick={() => handleTranslate()}>
                      Translate to{" "}
                      <LanguageCodeee languageCode={currentLanguageCode} /> with
                      AI
                    </Button>
                  )}
                </div>
              )}
            </div>
          ) : (
            <>
              {/* <Heading className="text-center mb-8">
                {article.title?.[currentLanguageCode]}
              </Heading> */}
              <Markdown
                markdown={
                  article?.markdownContent !== null
                    ? article?.markdownContent[currentLanguageCode]
                    : null
                }
              />
              {articleExistsInCurrentLanguage === true &&
                property !== null &&
                property.type === "internal" && (
                  <>
                    <div className="grid grid-cols-1 md:grid-cols-4 mt-32">
                      <Ul>
                        <Text className="font-bold">Author</Text>
                        <Li>
                          <Text>{property.name}</Text>
                        </Li>
                        <Li>
                          <Text>{property.email}</Text>
                        </Li>
                        <Li>
                          <Text>{property.phone}</Text>
                        </Li>
                        <Li>
                          <Text>
                            <TextLink
                              href={
                                "https://dashboard.juhuu.app/properties/" +
                                property.id +
                                "/articles/" +
                                article.id
                              }
                              target="_blank"
                            >
                              Edit article
                            </TextLink>
                          </Text>
                        </Li>
                      </Ul>
                      <Ul>
                        <Text className="font-bold">Article</Text>

                        <Li>
                          <Text>
                            Last updated{" "}
                            <Timestamp timestamp={article.lastUpdatedAt} />
                          </Text>
                        </Li>
                        <Li>
                          <Text>
                            Created <Timestamp timestamp={article.createdAt} />
                          </Text>
                        </Li>
                      </Ul>
                      <Ul>
                        <Text className="font-bold">JUHUU Buddy</Text>
                        <Li>
                          {article.embeddingsGenerationEnabled === false ? (
                            <Badge color="zinc">Blocked</Badge>
                          ) : article.embeddingsGenerated === true ? (
                            <Badge color="lime">Available</Badge>
                          ) : (
                            <Badge color="yellow">Pending</Badge>
                          )}
                        </Li>
                      </Ul>
                      <Ul>
                        <Text className="font-bold">Languages</Text>
                        {availableLanguageCodeArray.map((languageCode) => {
                          return (
                            <Li key={languageCode}>
                              <Text>
                                <LanguageCodeee languageCode={languageCode} />
                                {article.languageCodeArray.includes(
                                  languageCode
                                ) === false && " (AI translated)"}
                              </Text>
                            </Li>
                          );
                        })}
                      </Ul>
                    </div>
                  </>
                )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ArticleRoute;
