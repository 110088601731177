import React, {
  useContext,
  useState,
  createContext,
  useMemo,
  useCallback,
} from "react";
import { LanguageCode, LanguageCodeArray } from "@juhuu/sdk-ts";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from "../components/dialog";
import { Button } from "../components/button";
import LanguageCodeee from "./LanguageCodeee";

const LanguageContext = createContext<{
  openLanguageSelector: () => Promise<void>;
  currentLanguageCode: LanguageCode;
  setLanguage: (languageCode: LanguageCode) => Promise<void>;
}>({
  openLanguageSelector: async () => {},
  currentLanguageCode: "en",
  setLanguage: async () => {},
});

export function useLanguage() {
  return useContext(LanguageContext);
}

interface LanguageProviderProps {
  children?: React.ReactNode;
}

const LanguageProvider: React.FC<LanguageProviderProps> = ({
  children = null,
}) => {
  const [cachedLanguageCode, setCachedLanguageCode] =
    useState<LanguageCode | null>(null);
  const [languageSelectorOpen, setLanguageSelectorOpen] =
    useState<boolean>(false);

  const currentLanguageCode: LanguageCode = useMemo((): LanguageCode => {
    if (cachedLanguageCode !== null) {
      return cachedLanguageCode;
    }

    const storedLanguage = localStorage.getItem("languageCode");

    const detectedLanguage = navigator.language || navigator.languages[0];
    if (LanguageCodeArray.includes(storedLanguage as LanguageCode) === false) {
      if (
        LanguageCodeArray.includes(detectedLanguage as LanguageCode) === false
      ) {
        return "en";
      }

      return detectedLanguage as LanguageCode;
    }

    return storedLanguage as LanguageCode;
  }, [cachedLanguageCode]);

  const handleOpenLanguageSelector = useCallback(async (): Promise<void> => {
    setLanguageSelectorOpen(true);
  }, []);

  const handleSelectLanguage = useCallback(
    async (languageCode: LanguageCode): Promise<void> => {
      if (LanguageCodeArray.includes(languageCode) === false) {
        return;
      }

      setCachedLanguageCode(languageCode);
      localStorage.setItem("languageCode", languageCode);
      setLanguageSelectorOpen(false);
    },
    []
  );

  return (
    <LanguageContext.Provider
      value={{
        openLanguageSelector: handleOpenLanguageSelector,
        currentLanguageCode,
        setLanguage: handleSelectLanguage,
      }}
    >
      {children}
      <Dialog open={languageSelectorOpen} onClose={setLanguageSelectorOpen}>
        <DialogTitle>Select language</DialogTitle>
        <DialogDescription>
          Articles which are not written in your preferred language may be
          translated automatically using artificial intelligence.
        </DialogDescription>
        <DialogBody className="max-h-[70vh] overflow-y-auto">
          {LanguageCodeArray.map((languageCode) => {
            return (
              <p
                className={
                  "py-1 px-4 text-lg rounded-full cursor-pointer " +
                  (currentLanguageCode === languageCode ? "bg-zinc-200" : "")
                }
                key={languageCode}
                onClick={() => {
                  handleSelectLanguage(languageCode);
                }}
              >
                <LanguageCodeee languageCode={languageCode} />
              </p>
            );
          })}
        </DialogBody>
        <DialogActions>
          <Button onClick={() => setLanguageSelectorOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
